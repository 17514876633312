<template>
  <div class="american-questionnaire-wrap" id="american-questionnaire-wrap">
    <div class="box plain-shadow-on-desktop">
      <section class="content-wrapper">
        <div class="page-title has-text-weight-semibold has-text-centered mb-5">Suitability Questionnaire</div>
        <Questionnaire
          :onLoad="questionnaireOnLoad"
          :onChange="questionnaireOnChange"
          pageId="american-questionnaire-wrap"
        ></Questionnaire>
      </section>
    </div>
    <footer v-if="showBottomButtons" class="footer-buttons">
      <BottomButtons
        backButtonText="Back"
        :nextDisabled="!questionnaireCompleted"
        :showSteps="false"
        @goNext="goNext"
        @goBack="goBack"
      ></BottomButtons>
    </footer>
  </div>
</template>
<script>
import Questionnaire from '@views/profile/questionnaire/QuestionsForm.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import questionnaireMixins from '@utils/mixins/questionnaire-mixins.js'
import { saveQuestionnaire } from '@api/profile'
import { getInvestorData } from '@api/signup'
import { investorHasSameResidentialAndMailingAddresses } from '@utils/common-methods/investorOnboarding'
import { mapState } from 'vuex'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  mixins: [questionnaireMixins],
  components: {
    Questionnaire,
    BottomButtons,
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
  },
  methods: {
    goBack() {
      if (investorHasSameResidentialAndMailingAddresses()) {
        this.$router.push('/signup/address/1?mailingIsSame=true')
      } else {
        this.$router.push('/signup/address/2')
      }
    },
    goNext() {
      if (!this.questionnaireCompleted) return
      const payload = this.convertAnswersToArrayOnly()
      saveQuestionnaire(payload).then((res) => {
        if (!res.success) return
        getInvestorData().then((data) => {
          localStorage.setItem('investor', JSON.stringify(data))
          if (!data.eligibleUsAccreditedAccess) this.mixpanel.track('tie227ff')
          if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
          this.$router.push('/dashboard')
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.american-questionnaire-wrap {
  padding: 0 25px 140px;
  .box {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 26px;
    padding-bottom: 26px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    .content-wrapper {
      padding: 0 12px;
      .page-title {
       font-size: 32px;
       line-height: 32px;
       letter-spacing: 0.03em;
     }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .american-questionnaire-wrap .box .content-wrapper {
    padding: 0 20px;
  }
}
</style>
